@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400;600&family=Source+Sans+Pro:wght@400;600&display=swap");

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  background-color: #263238 !important;
  margin: 0 !important;
}

span,
div {
  font-family: new-hero, sans-serif;
}
